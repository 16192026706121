import axios from "axios";

export const api = axios.create({
  baseURL: process.env.REACT_APP_URL_PROTHEUS_API_PRODUCAO,
  headers: {
    Authorization: process.env.REACT_APP_PROTHEUS_HEADERS_AUTHORIZATION,
    "Content-Type": "application/json",
  },
});

export const instanceApi = (tenantId, isAuthentic = false) => {
  const localSession = JSON.parse(localStorage.getItem("session") || "{}");
  const token = localSession.apiProtheus?.access_token;

  const api = axios.create({
    baseURL: process.env.REACT_APP_URL_PROTHEUS_API_PRODUCAO,
    headers: {
      Authorization: `BASIC ${isAuthentic ? token : 'GV1948#145543'}`,
      "Content-Type": "application/json",
      tenantId,
    },
  });

  return api;
};

export const instanceApiAuth = async (tenantId) => {
  
  let tokenProtheus = localStorage.getItem("tokenProtheus") || "";
  let tokenProtheusExpiration = localStorage.getItem("tokenProtheusExpiration") || "";

  let userAuthProtheus = process.env.REACT_APP_USER_AUTH_PROTHEUS
  let passwordAuthProtheus = process.env.REACT_APP_PASSWORD_AUTH_PROTHEUS

  if (tokenProtheus === "" && tokenProtheusExpiration === "") {

    let expireted = new Date()
    let token = await api.post(`/all/api/oauth2/v1/token?grant_type=password&username=${userAuthProtheus}&password=${passwordAuthProtheus}`)
    localStorage.setItem("tokenProtheus", token.data.access_token)
    localStorage.setItem("refreshTokenProtheus", token.data.refresh_token)
    localStorage.setItem("tokenProtheusExpiration", expireted.setHours(expireted.getHours() + 1))
    tokenProtheus = token.data.access_token
  }

  if (tokenProtheus != "" && Date(tokenProtheusExpiration) <= Date()) {
    let expireted = new Date()
    let refreshTokenProtheus = localStorage.getItem("refreshTokenProtheus") || "";

    let token = await api.post("/all/api/oauth2/v1/token?grant_type=refresh_token&refresh_token=" + refreshTokenProtheus)
    localStorage.setItem("tokenProtheus", token.data.access_token)
    localStorage.setItem("refreshTokenProtheus", token.data.refresh_token)
    localStorage.setItem("tokenProtheusExpiration", expireted.setHours(expireted.getHours() + 1))
    tokenProtheus = token.data.access_token
  }

  const apiAuth = axios.create({
    baseURL: process.env.REACT_APP_URL_PROTHEUS_API_PRODUCAO,
    headers: {
      Authorization: `Bearer ${tokenProtheus}`,
      "Content-Type": "application/json",
      tenantId,
    },
  });


  return apiAuth
}

async function handleHttpError(error) {
  return error;
}

function makeHttpRequest(apiCall) {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await apiCall();
      resolve(data.data);
    } catch (e) {
      reject(handleHttpError(e));
    }
  });
}

export const getRequest = async (path, options = {}) =>
  await makeHttpRequest(() => api.get(path, options));
export const postRequest = (path, options = {}, headers = {}) =>
  makeHttpRequest(() => api.post(path, options, headers));
export const putRequest = (path, options = {}, headers) =>
  makeHttpRequest(() => api.put(path, options, headers));
export const deleteRequest = (path, options = {}) =>
  makeHttpRequest(() => api.delete(path, options));