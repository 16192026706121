import React from "react";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Home from "../pages/Home/";
import SignIn from "../pages/SignIn";
import ForgotPassword from "../pages/ForgotPassword/";
import ResetPassword from "../pages/ResetPassword/";
import NoRoute from "../pages/Error";

/** Administrative pages */
import HomeAdministrative from "../pages/_modules/administrative";
import Resources from "../pages/_modules/administrative/Resources";
import Users from "../pages/_modules/administrative/Users";
import Profiles from "../pages/_modules/administrative/Profiles";
import TemplatesMail from "../pages/_modules/administrative/TemplatesMail";
import Companies from "../pages/_modules/administrative/Companies";
import Modules from "../pages/_modules/administrative/Modules";
import Forms from "../pages/_modules/administrative/Forms";
import FormAdd from "../pages/_modules/administrative/Forms/components/Form";
import FormItemAdd from "../pages/_modules/administrative/Forms/components/FormItens";
import Products from "../pages/_modules/administrative/Products";
import Sectors from "../pages/_modules/administrative/Sectors";
import Goal from "../pages/_modules/administrative/Goal";
import Reports from "../pages/_modules/administrative/Reports";
import UserGroup from "../pages/_modules/administrative/UserGroup";
import ExternalLinks from "../pages/_modules/administrative/ExternalLinks";
import LinksGroup from "../pages/_modules/administrative/ExternalLinks/components/LinksGroupPage";
import SettingServices from "../pages/_modules/administrative/SettingServices";
import InsertOrUpdateSettingService from "../pages/_modules/administrative/SettingServices/components/InsertOrUpdateSettingService";
import TemplateDocument from "../pages/_modules/administrative/TemplateDocument";
import Notification from "../pages/_modules/administrative/Notification";
import NotificationsApplication from "../pages/_modules/administrative/NotificationsApplication";
import FieldRules from "../pages/_modules/administrative/FieldRules";

/** Commercial pages */
import HomeCommercial from "../pages/_modules/commercial";
import PlanContractApproval from "../pages/_modules/commercial/PlanContractApproval";

/** Customer Service Center pages */
import HomeCustomerServiceCenter from "../pages/_modules/customer-service-center";
import Leads from "../pages/_modules/customer-service-center/Leads";
import Calculator from "../pages/_modules/customer-service-center/Calculator";
import ScreenOrderService from "../pages/_modules/customer-service-center/ScreenOrderService";
import UnifiedQuery from "../pages/_modules/customer-service-center/UnifiedQuery";
import UnifiedQueryContract from "../pages/_modules/customer-service-center/UnifiedQuery/components/Contracts/Contract";
import UnifiedQueryAttendance from "../pages/_modules/customer-service-center/UnifiedQuery/components/Attendance";
import OfflineSearch from "../pages/_modules/customer-service-center/offlineSearch/";
import SearchOffline from "../pages/_modules/customer-service-center/offlineSearch/search";
import SearchAuthorization from "../pages/_modules/customer-service-center/offlineSearch/authorizations";

/** Funeral Service pages */
import HomeFuneralService from "../pages/_modules/funeral-service";
import BaseService from "../pages/_modules/funeral-service/base_service";
import RemovalAnticipationForm from "../pages/_modules/funeral-service/removal-anticipation-form";

/** Financial pages */
import HomeFinancial from "../pages/_modules/financial";
import Negotiation from "../pages/_modules/financial/negotiation";
import PaymentLink from "../pages/_modules/financial/paymentLink";
import Recurrence from "../pages/_modules/financial/recurrence";
import PixManagement from "../pages/_modules/financial/pix";
import ScheduleMonitor from "../pages/_modules/financial/scheduleMonitor";

/** Logistics pages */
import HomeLogistics from "../pages/_modules/logistics";
import MapsAgents from "../pages/_modules/logistics/maps-agents";
import { itensMenu as itensMenuLogistica } from "../pages/_modules/logistics/itens-menu";

/** Tanatopraxia pages */
import HomeTanatopraxia from "../pages/_modules/tanatopraxia";
import TanatopraxiaOrderService from "../pages/_modules/tanatopraxia/OrderService";
import Appointments from "../components/Appointments";
import OccurrenceRecord from "../pages/_modules/tanatopraxia/OccurrenceRecord";

/** Flower Shop pages */
import HomeFlowerShop from "../pages/_modules/flower-shop";
import FlowerShopOrderService from "../pages/_modules/flower-shop/OrderService";

/** Funerals Center pages */
import HomeFuneralsCenter from "../pages/_modules/funerals-center";
import FuneralsCenterOrderService from "../pages/_modules/funerals-center/OrderService";

/** Cemetery pages */
import HomeCemetery from "../pages/_modules/cemetery";
import ReservationSQL from "../pages/_modules/cemetery/reservation_sql";
import CemeteryOrderService from "../pages/_modules/cemetery/OrderService";

/** Undertaker pages */
import HomeUndertaker from "../pages/_modules/undertaker";

/** Thanatopraxist pages */
import HomeThanatopraxist from "../pages/_modules/thanatopraxist";

/** Florist pages */
import HomeFlorist from "../pages/_modules/florist";

/** Ceremonialist pages */
import HomeCeremonialist from "../pages/_modules/ceremonialist";

/** Cremationist pages */
import HomeCremationist from "../pages/_modules/cremationist";

/** Invoicing pages */
import HomeInvoicing from "../pages/_modules/invoicing";
import ObjectsCarrier from "../pages/_modules/invoicing/ObjectsCarrier";
import QrCodePage from "../pages/_modules/invoicing/ObjectsCarrier/components/QrCodePage";
import ReceiveObjects from "../pages/_modules/invoicing/ReceiveObjects";
import FormReturnObject from "../pages/_modules/invoicing/ObjectsCarrier/components/FormReturnObject";

import { signOut } from "../store/ducks/signin";

import { checkControl } from "../utils/access_control";
import { getToken } from "../utils/localStoreToken";
import { useSession } from "../Context/SessionProvider";

/** Client Non-private pages */
import DuplicateBillet from "../pages/_modules/client/duplicate_billet";
import Pix from "../pages/_modules/client/pix";
import ContractApproval from "../pages/Public/ContractApproval";

import AppointmentsWithParams from "../components/AppointmentsWithParams";
import Dashboard from "../pages/_modules/invoicing/Dashboard";
import LogisticsOrderService from "../pages/_modules/logistics/OrderService";
import Objects from "../pages/_modules/invoicing/Objects/index";
import UserLogs from "../pages/_modules/administrative/Users/components/Logs";
import UpdateCardRecurrence from "../pages/_modules/client/recurrence/update-card-recurrence";
import JoinRecurrence from "../pages/_modules/client/recurrence/join-recurrence";
import SendToProduction from "../pages/_modules/invoicing/SendToProduction";
import HuggyBackup from "../pages/_modules/customer-service-center/HuggyBackup";
import { HuggyChat } from "../pages/_modules/customer-service-center/HuggyBackup/HuggyChat";
import CustomerAppList from "../pages/_modules/customer-service-center/CustomerAppList/index";

const App = () => {
  const modules = useSelector((state) => state.modules.modules);

  let modules_conf = [
    { resource: "access_administrative_module", path: null, name: null },
    {
      resource: "access_customer_service_center_module",
      path: null,
      name: null,
    },
    { resource: "access_funeral_service_module", path: null, name: null },
    { resource: "access_logistics_module", path: null, name: null },
    { resource: "access_tanatopraxia_module", path: null, name: null },
    { resource: "access_flower_shop_module", path: null, name: null },
    { resource: "access_funerals_center_module", path: null, name: null },
    { resource: "access_cemetery_module", path: null, name: null },
    { resource: "access_undertaker_module", path: null, name: null },
    { resource: "access_thanatopraxist_module", path: null, name: null },
    { resource: "access_florist_module", path: null, name: null },
    { resource: "access_ceremonialist_module", path: null, name: null },
    { resource: "access_cremationist_module", path: null, name: null },
    { resource: "access_module_financial", path: null, name: null },
    { resource: "access_module_invoicing", path: null, name: null },
    { resource: "acesso_modulo_comercial", path: null, name: null },
  ];

  modules.map((module) => {
    modules_conf.map((conf) => {
      if (conf.resource === module.Resource.class_tag) {
        conf.path = module.path;
        conf.name = module.name;
      }
    });
  });

  const administrative = modules_conf[0];
  const customer_service_center = modules_conf[1];
  const funeral_service = modules_conf[2];
  const logistics = modules_conf[3];
  const tanatopraxia = modules_conf[4];
  const flower_shop = modules_conf[5];
  const funerals_center = modules_conf[6];
  const cemetery = modules_conf[7];
  const undertaker = modules_conf[8];
  const thanatopraxist = modules_conf[9];
  const florist = modules_conf[10];
  const ceremonialist = modules_conf[11];
  const cremationist = modules_conf[12];
  const financial = modules_conf[13];
  const invoicing = modules_conf[14];
  const commercial = modules_conf[15];

  return (
    <BrowserRouter>
      <Switch>
        {/* Non-private routes */}
        <Route path="/signin" component={SignIn} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password" component={ResetPassword} />

        {/* Client Non-private pages*/}
        <Route path="/segundavia" component={DuplicateBillet} />
        <Route path="/pix/:base64" component={Pix} />
        <Route path="/contract-approval" component={ContractApproval} />
        <Route path="/recorrencia/atualizacao-cartao/:token" component={UpdateCardRecurrence} />
        <Route path="/recorrencia/adesao/:token" component={JoinRecurrence} />


        {/* Home route */}
        <PrivateRoute exact path="/" access="access_menu_home">
          <Home moduleName="Início" />
        </PrivateRoute>

        {/* Administrative routes */}
        <PrivateRoute
          exact
          path={"/" + administrative.path}
          access={administrative.resource}
        >
          <HomeAdministrative
            moduleName={administrative.name}
            modulePath={administrative.path}
          />
        </PrivateRoute>
        <PrivateRoute
          path={"/" + administrative.path + "/resources"}
          access="access_menu_resources"
        >
          <Resources
            moduleName={administrative.name}
            modulePath={administrative.path}
          />
        </PrivateRoute>
        <PrivateRoute
          path={"/" + administrative.path + "/users/:id/logs"}
          access="access_menu_user_groups"
        >
          <UserLogs
            moduleName={administrative.name}
            modulePath={administrative.path}
          />
        </PrivateRoute>
        <PrivateRoute
          path={"/" + administrative.path + "/users"}
          access="access_menu_users"
        >
          <Users
            moduleName={administrative.name}
            modulePath={administrative.path}
          />
        </PrivateRoute>
        <PrivateRoute
          path={"/" + administrative.path + "/user-groups"}
          access="access_menu_user_groups"
        >
          <UserGroup
            moduleName={administrative.name}
            modulePath={administrative.path}
          />
        </PrivateRoute>
        <PrivateRoute
          path={"/" + administrative.path + "/profiles"}
          access="access_menu_profiles"
        >
          <Profiles
            moduleName={administrative.name}
            modulePath={administrative.path}
          />
        </PrivateRoute>
        <PrivateRoute
          path={"/" + administrative.path + "/template-mail"}
          access="access_menu_template_mails"
        >
          <TemplatesMail
            moduleName={administrative.name}
            modulePath={administrative.path}
          />
        </PrivateRoute>
        <PrivateRoute
          path={"/" + administrative.path + "/companies"}
          access="access_menu_companys"
        >
          <Companies
            moduleName={administrative.name}
            modulePath={administrative.path}
          />
        </PrivateRoute>
        <PrivateRoute
          path={"/" + administrative.path + "/modules"}
          access="access_menu_module"
        >
          <Modules
            moduleName={administrative.name}
            modulePath={administrative.path}
          />
        </PrivateRoute>
        <PrivateRoute
          path={"/" + administrative.path + "/forms"}
          access="access_menu_forms"
        >
          <Forms
            moduleName={administrative.name}
            modulePath={administrative.path}
          />
        </PrivateRoute>
        <PrivateRoute
          path={"/" + administrative.path + "/add/form"}
          access="insert_forms"
        >
          <FormAdd
            moduleName={administrative.name}
            modulePath={administrative.path}
          />
        </PrivateRoute>
        <PrivateRoute
          path={"/" + administrative.path + "/item/add/form"}
          access="insert_forms"
        >
          <FormItemAdd
            moduleName={administrative.name}
            modulePath={administrative.path}
          />
        </PrivateRoute>
        <PrivateRoute
          path={"/" + administrative.path + "/products"}
          access="access_menu_products"
        >
          <Products
            moduleName={administrative.name}
            modulePath={administrative.path}
          />
        </PrivateRoute>
        <PrivateRoute
          path={"/" + administrative.path + "/service_management/setors"}
          access="access_menu_sectors"
        >
          <Sectors
            moduleName={administrative.name}
            modulePath={administrative.path}
          />
        </PrivateRoute>

        <PrivateRoute
          path={"/" + administrative.path + "/administrative/goal"}
          access="access_menu_goal"
        >
          <Goal
            moduleName={administrative.name}
            modulePath={administrative.path}
          />
        </PrivateRoute>

        <PrivateRoute
          path={"/" + administrative.path + "/template/documents"}
          access="access_menu_template_docs"
        >
          <TemplateDocument
            moduleName={administrative.name}
            modulePath={administrative.path}
          />
        </PrivateRoute>
        <PrivateRoute
          path={"/" + administrative.path + "/service-management/setting-services/field-rules"}
          access="access_menu_field_rules"
        >
          <FieldRules
            moduleName={administrative.name}
            modulePath={administrative.path}
          />
        </PrivateRoute>
        <PrivateRoute
          path={
            "/" + administrative.path + "/service-management/setting-services"
          }
          access="access_menu_setting_services"
        >
          <SettingServices
            moduleName={administrative.name}
            modulePath={administrative.path}
          />
        </PrivateRoute>
        <PrivateRoute
          path={
            "/" + administrative.path + "/setting-services/insert-or-update"
          }
          access="save_and_update_setting_services"
        >
          <InsertOrUpdateSettingService
            moduleName={administrative.name}
            modulePath={administrative.path}
          />
        </PrivateRoute>


        <PrivateRoute
          path={"/" + administrative.path + "/reports"}
          access="access_menu_reports_os"
        >
          <Reports
            moduleName={administrative.name}
            modulePath={administrative.path}
          />
        </PrivateRoute>
        {/* ExternalLinks routes */}
        <PrivateRoute
          path={"/" + administrative.path + "/external-links/:code"}
          access="access_menu_reports_os"
        >
          <LinksGroup
            moduleName={administrative.name}
            modulePath={administrative.path}
          />
        </PrivateRoute>

        <PrivateRoute
          path={"/" + administrative.path + "/external-links/"}
          access="access_menu_reports_os"
        >
          <ExternalLinks
            moduleName={administrative.name}
            modulePath={administrative.path}
          />
        </PrivateRoute>
        {/* Notifications routes */}
        <PrivateRoute
          path={"/" + administrative.path + "/notifications"}
          access="access_menu_notification"
        >
          <Notification
            moduleName={administrative.name}
            modulePath={administrative.path}
          />
        </PrivateRoute>

        {/* Notificaions application routes */}

        <PrivateRoute
          path={"/" + administrative.path + "/notifications-application"}
          access="access_menu_notification"
        >
          <NotificationsApplication
            moduleName={administrative.name}
            modulePath={administrative.path}
          />
        </PrivateRoute>

        {/* Commercial routes */}
        <PrivateRoute
          exact
          path={"/" + commercial.path}
          access={commercial.resource}
        >
          <HomeCommercial
            moduleName={commercial.name}
            modulePath={commercial.path}
          />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={"/" + commercial.path + "/plan-contract-approval"}
          access="visualiza_contratos_app"
        >
          <PlanContractApproval
            moduleName={commercial.name}
            modulePath={commercial.path}
          />
        </PrivateRoute>

        {/* Customer Service Center routes */}
        <PrivateRoute
          exact
          path={"/" + customer_service_center.path}
          access={customer_service_center.resource}
        >
          <HomeCustomerServiceCenter
            moduleName={customer_service_center.name}
            modulePath={customer_service_center.path}
          />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={"/" + customer_service_center.path + "/customer-app"}
          access="access_customer_app_list"
        >
          <CustomerAppList
            moduleName={customer_service_center.name}
            modulePath={customer_service_center.path}
          />
        </PrivateRoute>

        <PrivateRoute
          exact
          path={"/" + customer_service_center.path + "/offline-search"}
          access="access_offline_search"
        >

          <OfflineSearch moduleName={customer_service_center.name}
            modulePath={customer_service_center.path} />
        </PrivateRoute>

        <PrivateRoute
          exact
          path={"/" + customer_service_center.path + "/offline-search/search"}
          access="access_offline_search"
        >

          <SearchOffline moduleName={customer_service_center.name}
            modulePath={customer_service_center.path} />
        </PrivateRoute>

        <PrivateRoute
          exact
          path={"/" + customer_service_center.path + "/offline-search/authorization"}
          access="access_offline_search"
        >

          <SearchAuthorization moduleName={customer_service_center.name}
            modulePath={customer_service_center.path} />
        </PrivateRoute>


        <PrivateRoute
          exact
          path={"/" + customer_service_center.path + "/leads"}
          access="access_menu_leads"
        >
          <Leads
            moduleName={customer_service_center.name}
            modulePath={customer_service_center.path}
          />
        </PrivateRoute>

        {/* Huggy backup */}
        <PrivateRoute
          exact
          path={"/" + customer_service_center.path + "/huggy-backup"}
          access="access_menu_huggy_backup"
        >
          <HuggyBackup
            moduleName={customer_service_center.name}
            modulePath={customer_service_center.path}
          />
        </PrivateRoute>

        <PrivateRoute
          exact
          path={"/" + customer_service_center.path + "/huggy-backup/chats/:chatId"}
          access="access_menu_huggy_backup"
        >
          <HuggyChat
            moduleName={customer_service_center.name}
            modulePath={customer_service_center.path}
          />
        </PrivateRoute>
        {/* End Huggy backup */}
        <PrivateRoute
          exact
          path={"/" + customer_service_center.path + "/calculator"}
          access="access_menu_calculator"
        >
          <Calculator
            moduleName={customer_service_center.name}
            modulePath={customer_service_center.path}
          />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={"/" + customer_service_center.path + "/attendances"}
          access="access_attendances"
        >
          <ScreenOrderService
            moduleName={customer_service_center.name}
            modulePath={customer_service_center.path}
          />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={"/" + customer_service_center.path + "/unified/query"}
          access="access_unified_query"
        >
          <UnifiedQuery
            moduleName={customer_service_center.name}
            modulePath={customer_service_center.path}
          />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={"/" + customer_service_center.path + "/unified/query/contract"}
          access="access_unified_query"
        >
          <UnifiedQueryContract
            moduleName={customer_service_center.name}
            modulePath={customer_service_center.path}
          />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={
            "/" + customer_service_center.path + "/unified/query/attendance"
          }
          access="access_unified_query"
        >
          <UnifiedQueryAttendance
            moduleName={customer_service_center.name}
            modulePath={customer_service_center.path}
          />
        </PrivateRoute>

        {/* Funeral Service routes */}
        <PrivateRoute
          exact
          path={"/" + funeral_service.path}
          access={funeral_service.resource}
        >
          <HomeFuneralService
            moduleName={funeral_service.name}
            modulePath={funeral_service.path}
          />
        </PrivateRoute>
        <PrivateRoute
          path={"/" + funeral_service.path + "/base_services"}
          access="access_menu_base_services"
        >
          <BaseService
            moduleName={funeral_service.name}
            modulePath={funeral_service.path}
          />
        </PrivateRoute>
        <PrivateRoute
          path={"/" + funeral_service.path + "/removal-anticipation-form"}
          access="access_menu_removal-anticipation-form"
        >
          <RemovalAnticipationForm
            moduleName={funeral_service.name}
            modulePath={funeral_service.path}
          />
        </PrivateRoute>

        {/* Financial routes */}
        <PrivateRoute
          exact
          path={"/" + financial.path}
          access={financial.resource}
        >
          <HomeFinancial
            moduleName={financial.name}
            modulePath={financial.path}
          />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={"/" + financial.path + "/negotiation"}
          access={financial.resource}
        >
          <Negotiation
            moduleName={financial.name}
            modulePath={financial.path}
          />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={"/" + financial.path + "/payment-link"}
          access={financial.resource}
        >
          <PaymentLink
            moduleName={financial.name}
            modulePath={financial.path}
          />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={"/" + financial.path + "/recurrence"}
          access={financial.resource}
        >
          <Recurrence moduleName={financial.name} modulePath={financial.path} />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={"/" + financial.path + "/payment-pix"}
          access={financial.resource}
        >
          <PixManagement moduleName={financial.name} modulePath={financial.path} />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={"/" + financial.path + "/schedule-monitor"}
          access="access_menu_monitor_schedule"
        >
          <ScheduleMonitor moduleName={financial.name} modulePath={financial.path} />
        </PrivateRoute>

        {/* Logistics routes */}
        <PrivateRoute
          exact
          path={"/" + logistics.path}
          access={logistics.resource}
        >
          <HomeLogistics
            moduleName={logistics.name}
            modulePath={logistics.path}
          />
        </PrivateRoute>
        <PrivateRoute
          path={"/" + logistics.path + "/order-service"}
          access="access_menu_order_service"
        >
          {/* Kanban Logistica */}
          <LogisticsOrderService
            moduleName={logistics.name}
            modulePath={logistics.path}
          />
        </PrivateRoute>

        <PrivateRoute
          path={
            "/" +
            logistics.path +
            "/appointment/:appointment_id/:num_attendance_erp/:id_general_status/:setting_service_id/:isMobileParam"
          }
          access={logistics.resource}
        >
          <AppointmentsWithParams
            moduleName={logistics.name}
            modulePath={logistics.path}
          />
        </PrivateRoute>

        <PrivateRoute
          path={
            "/" +
            logistics.path +
            "/appointment/:appointment_id/:num_attendance_erp/:id_general_status/:setting_service_id"
          }
          access={logistics.resource}
        >
          <AppointmentsWithParams
            moduleName={logistics.name}
            modulePath={logistics.path}
          />
        </PrivateRoute>

        <PrivateRoute
          exact
          path={"/" + logistics.path + "/maps-agents"}
          access={logistics.resource}
        >
          <MapsAgents moduleName={logistics.name} modulePath={logistics.path} />
        </PrivateRoute>

        <PrivateRoute
          path={"/" + logistics.path + "/appointments"}
          access="access_menu_order_service"
        >
          <Appointments
            moduleName={logistics.name}
            modulePath={logistics.path}
          />
        </PrivateRoute>

        {/* Tanatopraxia routes */}
        <PrivateRoute
          exact
          path={"/" + tanatopraxia.path}
          access={tanatopraxia.resource}
        >
          <HomeTanatopraxia
            moduleName={tanatopraxia.name}
            modulePath={tanatopraxia.path}
          />
        </PrivateRoute>
        <PrivateRoute
          path={"/" + tanatopraxia.path + "/order-service"}
          access="access_menu_order_service"
        >
          <TanatopraxiaOrderService
            moduleName={tanatopraxia.name}
            modulePath={tanatopraxia.path}
          />
        </PrivateRoute>
        <PrivateRoute
          path={
            "/" +
            tanatopraxia.path +
            "/appointment/:appointment_id/:num_attendance_erp/:id_general_status/:setting_service_id"
          }
          access={tanatopraxia.resource}
        >
          <AppointmentsWithParams
            moduleName={tanatopraxia.name}
            modulePath={tanatopraxia.path}
          />
        </PrivateRoute>

        <PrivateRoute
          path={"/" + tanatopraxia.path + "/appointments"}
          access="access_menu_order_service"
        >
          <Appointments
            moduleName={tanatopraxia.name}
            modulePath={tanatopraxia.path}
          />
        </PrivateRoute>

        <PrivateRoute
          path={"/" + tanatopraxia.path + "/shift-change"}
          access="access_tanatopraxia_module"
        >
          <OccurrenceRecord
            moduleName={tanatopraxia.name}
            modulePath={tanatopraxia.path}
          />
        </PrivateRoute>

        {/* Flower Shop routes */}
        <PrivateRoute
          exact
          path={"/" + flower_shop.path}
          access={flower_shop.resource}
        >
          <HomeFlowerShop
            moduleName={flower_shop.name}
            modulePath={flower_shop.path}
          />
        </PrivateRoute>
        <PrivateRoute
          path={"/" + flower_shop.path + "/order-service"}
          access="access_menu_order_service"
        >
          <FlowerShopOrderService
            moduleName={flower_shop.name}
            modulePath={flower_shop.path}
          />
        </PrivateRoute>
        <PrivateRoute
          path={
            "/" +
            flower_shop.path +
            "/appointment/:appointment_id/:num_attendance_erp/:id_general_status/:setting_service_id"
          }
          access={flower_shop.resource}
        >
          <AppointmentsWithParams
            moduleName={flower_shop.name}
            modulePath={flower_shop.path}
          />
        </PrivateRoute>
        <PrivateRoute
          path={"/" + flower_shop.path + "/appointments"}
          access="access_menu_order_service"
        >
          <Appointments
            moduleName={flower_shop.name}
            modulePath={flower_shop.path}
          />
        </PrivateRoute>

        {/* Funerals Center routes */}
        <PrivateRoute
          exact
          path={"/" + funerals_center.path}
          access={funerals_center.resource}
        >
          <HomeFuneralsCenter
            moduleName={funerals_center.name}
            modulePath={funerals_center.path}
          />
        </PrivateRoute>
        <PrivateRoute
          path={"/" + funerals_center.path + "/order-service"}
          access="access_menu_order_service"
        >
          <FuneralsCenterOrderService
            moduleName={funerals_center.name}
            modulePath={funerals_center.path}
          />
        </PrivateRoute>
        <PrivateRoute
          path={"/" + funerals_center.path + "/appointments"}
          access="access_menu_order_service"
        >
          <Appointments
            moduleName={funerals_center.name}
            modulePath={funerals_center.path}
          />
        </PrivateRoute>
        <PrivateRoute
          path={
            "/" +
            funerals_center.path +
            "/appointment/:appointment_id/:num_attendance_erp/:id_general_status/:setting_service_id"
          }
          access={funerals_center.resource}
        >
          <AppointmentsWithParams
            moduleName={funerals_center.name}
            modulePath={funerals_center.path}
          />
        </PrivateRoute>

        {/* Cemetery routes */}
        <PrivateRoute
          exact
          path={"/" + cemetery.path}
          access={cemetery.resource}
        >
          <HomeCemetery moduleName={cemetery.name} modulePath={cemetery.path} />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={"/" + cemetery.path + "/reservation/sql"}
          access="access_menu_reservation_sql"
        >
          <ReservationSQL
            moduleName={cemetery.name}
            modulePath={cemetery.path}
          />
        </PrivateRoute>

        <PrivateRoute
          exact
          path={"/" + cemetery.path + "/order-service"}
          access="access_menu_order_service"
        >
          <CemeteryOrderService
            moduleName={cemetery.name}
            modulePath={cemetery.path}
          />
        </PrivateRoute>
        <PrivateRoute
          path={
            "/" +
            cemetery.path +
            "/appointment/:appointment_id/:num_attendance_erp/:id_general_status/:setting_service_id"
          }
          access={cemetery.resource}
        >
          <AppointmentsWithParams
            moduleName={cemetery.name}
            modulePath={cemetery.path}
          />
        </PrivateRoute>
        <PrivateRoute
          path={"/" + cemetery.path + "/appointments"}
          access="access_menu_order_service"
        >
          <Appointments moduleName={cemetery.name} modulePath={cemetery.path} />
        </PrivateRoute>

        {/* Undertaker routes */}
        <PrivateRoute
          exact
          path={"/" + undertaker.path}
          access={undertaker.resource}
        >
          <HomeUndertaker
            moduleName={undertaker.name}
            modulePath={undertaker.path}
          />
        </PrivateRoute>

        {/* Thanatopraxist routes */}
        <PrivateRoute
          exact
          path={"/" + thanatopraxist.path}
          access={thanatopraxist.resource}
        >
          <HomeThanatopraxist
            moduleName={thanatopraxist.name}
            modulePath={thanatopraxist.path}
          />
        </PrivateRoute>

        {/* Florist routes */}
        <PrivateRoute exact path={"/" + florist.path} access={florist.resource}>
          <HomeFlorist moduleName={florist.name} modulePath={florist.path} />
        </PrivateRoute>

        {/* Ceremonialist routes */}
        <PrivateRoute
          exact
          path={"/" + ceremonialist.path}
          access={ceremonialist.resource}
        >
          <HomeCeremonialist
            moduleName={ceremonialist.name}
            modulePath={ceremonialist.path}
          />
        </PrivateRoute>

        {/* Cremationist routes */}
        <PrivateRoute
          exact
          path={"/" + cremationist.path}
          access={cremationist.resource}
        >
          <HomeCremationist
            moduleName={cremationist.name}
            modulePath={cremationist.path}
          />
        </PrivateRoute>

        {/** Invoicing routes */}
        <PrivateRoute
          exact
          path={"/" + invoicing.path}
          access={invoicing.resource}
        >
          <HomeInvoicing
            moduleName={invoicing.name}
            modulePath={invoicing.path}
          />
        </PrivateRoute>

        <PrivateRoute
          exact
          path={`/${invoicing.path}/objects`}
          access="access_page_objects"
        >
          <Objects moduleName={invoicing.name} modulePath={invoicing.path} />
        </PrivateRoute>

        <PrivateRoute
          exact
          path={`/${invoicing.path}/objects/carrier`}
          access="access_page_objects_invoicing"
        >
          <ObjectsCarrier
            moduleName={invoicing.name}
            modulePath={invoicing.path}
          />
        </PrivateRoute>

        <PrivateRoute
          exact
          path={`/${invoicing.path}/objects/read-barcode`}
          access="access_page_read_barcode_objects"
        >
          <QrCodePage moduleName={invoicing.name} modulePath={invoicing.path} />
        </PrivateRoute>

        <PrivateRoute
          path={`/${invoicing.path}/objects/read-barcode/form-return-object`}
          access="access_page_form_return_object"
        >
          <FormReturnObject
            moduleName={invoicing.name}
            modulePath={invoicing.path}
          />
        </PrivateRoute>

        <PrivateRoute
          exact
          path={`/${invoicing.path}/objects/receive-objects`}
          access="access_page_receive_objects"
        >
          <ReceiveObjects
            moduleName={invoicing.name}
            modulePath={invoicing.path}
            step="receive"
          />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={`/${invoicing.path}/objects/send-to-production`}
          access="access_page_send_objects_to_production"//TODO: criari acesso
        >
          <SendToProduction
            moduleName={invoicing.name}
            modulePath={invoicing.path}
          />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={`/${invoicing.path}/objects/send-to-carrier`}
          access="access_page_send_to_carrier"
        >
          <ReceiveObjects
            moduleName={invoicing.name}
            modulePath={invoicing.path}
            step="carrier"
          />
        </PrivateRoute>

        <PrivateRoute
          exact
          path={`/${invoicing.path}/objects/dashboard`}
          access="access_page_objects_dashboard"
        >
          <Dashboard moduleName={invoicing.name} modulePath={invoicing.path} />
        </PrivateRoute>

        {/* Not found route */}
        <Route path="*">
          <NoRoute
            status="404"
            title="Página não encontrada"
            message="Ops... Parece que a página que você está tentando acessar não existe."
          />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

function PrivateRoute({ children, ...rest }) {
  const signin = useSelector((state) => state.signin);
  const token = getToken();
  const dispatch = useDispatch();
  const { signout, session } = useSession()

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (
          token &&
          checkControl(signin.user.profiles, rest.access).display !== "none"
        ) {
          return children;
        } else if (
          token &&
          checkControl(signin.user.profiles, rest.access).display === "none"
        ) {
          return (
            <NoRoute
              status="403"
              title="Acesso não autorizado"
              message="Ops... Parece que você não tem permissão para acessar esta página."
            />
          );
        } else {
          dispatch(signOut());
          if (session.user?.id) {
            signout()
          }
          return (
            <Redirect
              to={{
                pathname: "/signin",
                state: { from: location },
              }}
            />
          );
        }
      }}
    />
  );
}

export default App;
