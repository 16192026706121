import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./styles.css";

import {
  Form,
  Input,
  Button,
  Select,
  message,
  Popconfirm,
  Spin,
  Switch,
  TreeSelect,
  Upload,
  Row,
  Col,
  Steps,
  Divider,
} from "antd";

import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";

import MaskedInput from "antd-mask-input";
import ImgCrop from "antd-img-crop";

import {
  addUser,
  alterUser,
  updateUser,
  deleteUser,
} from "../../../../../../store/ducks/users";

import { closeModal } from "../../../../../../store/ducks/modalGlobal";
import { instanceApi } from "../../../../../../utils/apiProtheus";
import { UserContext } from "../../../../../../Context/userContext";
import InputMask from "react-input-mask";

import {
  addUserApi,
  updateUserApi,
  deleteUserApi,
  resetPasswordUserApi
} from "../../../../../../services/users";

import { awsApi } from "../../../../../../services/aws";

import { checkControl } from "../../../../../../utils/access_control";
import { serviceProtheus } from "../../../../../../services/usersProtheus";
import Profile from "./components/Profile";
import Company from "./components/Company";
import { useUploadFile } from "../../../../../../utils/useUploadFile";
import { checkCNPJ, checkCPF } from "../../../../../../utils/validations";
import { vendedorAPI } from "../../../../../../services/validateSaller";

const { Option } = Select;
const { TreeNode } = TreeSelect;

const validateMessages = {
  required: "${label} é obrigatório!",
};

const FormApp = (props) => {
  const signin = useSelector((state) => state.signin);
  const user = useSelector((state) => state.users.user);

  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [code_user_protheus, setCode_user_protheus] = useState("");
  const [code_seller_protheus, setCode_seller_protheus] = useState("");
  const [cpf_user, setCpf_user] = useState("");
  const [shippingCompanies, setShippingCompanies] = useState([]);
  const [nameShippingCompany, setNameShippingCompany] = useState("");
  const [loadingShippingCompany, setLoadingShippinhCompany] = useState(false);
  const [stepCurrent, setStepCurrent] = useState(0);
  const {
    data: uploadImageData,
    uploadFile,
    loading: uploadImageLoading,
    reset: resetUploadData,
  } = useUploadFile();

  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const cpfField = Form.useWatch("cpf", form)
  const cpfFieldLength = cpfField?.replace(/\D/g,'')?.length

  useEffect(() => {
    if (user.companies) {
      setCompanies(user.companies);
    }

    if (user.profiles) {
      setProfiles(user.profiles);
    }
  }, [user]);

  useEffect(() => {
    getShippingCompanies();
  }, [nameShippingCompany]);

  useEffect(() => {
    form.resetFields();
    if (user.id && user.photo) {
      (async () => {
        const url = await awsApi.fetchFile(user.photo);
        setImageUrl(url);
      })();
    } else {
      setImageUrl("");
    }
  }, [user]);

  // useEffect(() => {
  //   userProtheusFunc().then((response) => {
  //     form.setFieldsValue({
  //       name_user_protheus: response ? response : null,
  //     });
  //   });
  // }, [code_user_protheus]);

  useEffect(() => {
    sellerProtheusFunc().then((response) => {
      form.setFieldsValue({
        name_seller_protheus: response ? response : null,
      });
    });
  }, [code_seller_protheus]);

  async function getShippingCompanies() {
    setLoadingShippinhCompany(true);
    try {
      const result = await instanceApi(signin.user.tenantId).get(
        `/03/RESTtransportadora?nome=${nameShippingCompany}&limit=200&offset=1`
      );

      setShippingCompanies(result.data.rows);
    } catch (error) {
      console.log("Erro ao buscar os transportadores", error);
    } finally {
      setLoadingShippinhCompany(false);
    }
  }

  async function sellerProtheusFunc() {

    if (!cpf_user && code_seller_protheus.replace("_", "").length >= 1) {
      message.warning(
        "Digite o CPF do colaborador primeiro"
      );
    }
    if (typeof code_seller_protheus !== null &&
      code_seller_protheus.replace("_", "").length === 6 && cpf_user.replaceAll(".", "").replaceAll("-", "").replaceAll("/", "").length == 11) {
      setLoading(true);

      const data = await vendedorAPI.getVendedor(code_seller_protheus, cpf_user.replaceAll(".", "").replaceAll("-", "").replaceAll("/", ""))
        .catch((error) => {
          message.warning(
            "Vendedor(a) não encontrado! Por favor, tente novamente,"
          );
          setLoading(false);
          return
        });
      setLoading(false);
      return data? data.data.name : ""
    }
  }

  async function userProtheusFunc() {
    if (
      typeof code_user_protheus !== null &&
      code_user_protheus.replace("_", "").length === 6
    ) {
      setLoading(true);

      const tmpUser = await serviceProtheus
        .userProtheus(`/03/api/framework/v1/users/${code_user_protheus}`)
        .catch((error) => {
          message.warning(
            "Usuário do Proehus não encontrado. Não será gravado o código informado."
          );
          setLoading(false);
        });

      setLoading(false);
      return "tmpUser.userName";
    }
  }

  const handleSubmit = async (values) => {
    setLoading(true);

    const messageError =
      "Erro ao criar usuário! favor verificar se os dados informados já estão cadastrados.";

    // if (values.name_user_protheus === null) {
    //   values.code_user_protheus = null;
    // }

    const reduceCompanies = companies.reduce((prev, curr) => {
      const { children, ...companie } = curr;
      return [...prev, companie].concat(children || []);
    }, []);

    if (!user.id) {
      await addUserApi
        .addUser({
          ...values,
          profiles,
          userCompanies: reduceCompanies,
          photo: uploadImageData?.key,
        })
        .then(async (res) => {
          if (res.errors) {
            setLoading(false);
            return message.error(messageError);
          }

          dispatch(addUser(res));

          dispatch(alterUser({}));
          dispatch(closeModal(false));
          setLoading(false);
          message.success("Usuário criado com sucesso!");
        })
        .catch(() => {
          setLoading(false);
          message.error(messageError);
        });
    } else {
      const body = {
        ...user,
        ...values,
        profiles,
        userCompanies: reduceCompanies,
        photo: uploadImageData?.Key || user.photo,
      };

      if (body.cpf) {
        body.cpf = body.cpf.replace(/\D/g, "");
      }

      if (body.phone) {
        body.phone = body.phone.replace(/\D/g, "");
      }

      await updateUserApi
        .updateUser(body)
        .then((res) => {
          if (uploadImageData && uploadImageData.key !== user.photo) {
            awsApi.deleteFile(user.photo);
          }
          dispatch(updateUser(res));
          dispatch(alterUser({}));
          dispatch(closeModal(false));
          message.success("Usuário alterado com sucesso!");
        })
        .catch((err) => {
          message.error(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    setImageUrl(null);
    resetUploadData();
  };

  const reset = async () => {
    await resetPasswordUserApi.resetUser(user.id).then((res) => {
      dispatch(deleteUser(res));
      dispatch(alterUser({}));
      dispatch(closeModal(false));
      setLoading(false);
      message.success("Senha restaurada com sucesso");
    })
      .catch((err) => {
        setLoading(false);
        message.error(err.message);
      });
  }

  const remove = async () => {
    setLoading(true);

    await deleteUserApi
      .deleteUser(user.id)
      .then((res) => {
        dispatch(deleteUser(res));
        dispatch(alterUser({}));
        dispatch(closeModal(false));
        setLoading(false);
        message.success("Usuário inativado com sucesso!");
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message);
      });
  };

  function beforeUpload(file) {
    const validTypes = ["jpeg", "jpg", "png", "webp"];
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!validTypes.includes(file.type.split("/")[1].toLowerCase())) {
      message.error("Apenas imagens dos tipos JPG/PNG são aceitas!");
    }
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
      message.error("O tamanho da imagem excede 4MB!");
    }
    return isJpgOrPng && isLt2M;
  }

  const uploadButton = (
    <div>
      {uploadImageLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const steps = [
    {
      title: "Perfis",
      content: <Profile />,
    },
    {
      title: "Empresas",
      content: <Company />,
    },
  ];

  return (
    <UserContext.Provider
      value={{
        userProfiles: profiles,
        setUserProfiles: setProfiles,
        userCompanies: companies,
        setUserCompanies: setCompanies,
      }}
    >
      <Spin tip="Loading..." spinning={uploadImageLoading || loading}>
        <Form
          layout="vertical"
          form={form}
          name="form-users"
          onFinish={handleSubmit}
          validateMessages={validateMessages}
          initialValues={{
            photo: user.photo,
            name: user.name,
            email: user.email,
            cpf: user.cpf || "",
            phone: user.phone,
            id_profile: user.profiles
              ? user.profiles.map((profile) => profile.id)
              : [],
            id_company: user.companies
              ? user.companies.map((company) => company.id)
              : [],
            code_user_protheus: user.code_user_protheus,
            name_user_protheus: user.name_user_protheus,
            carrier_id: user.carrier_id,
            code_customer_protheus: user.code_customer_protheus,
          }}
        >
          <Row>
            <Col xs={24} md={4}>
              <Form.Item name="photo" label="Foto">
                <ImgCrop
                  rotate
                  modalTitle="Editar imagem"
                  modalCancel="Cancelar"
                >
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    action={(file) => {
                      setImageUrl(URL.createObjectURL(file));
                      uploadFile(file, {
                        folder: "users",
                        format: "jpeg",
                        prefixname: "profile_picture",
                        quality: 95,
                        width: 200,
                      });
                    }}
                  // onChange={handleChange}
                  >
                    {imageUrl ? (
                      <img
                        src={imageUrl}
                        alt="avatar"
                        style={{ width: "100%" }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </ImgCrop>
              </Form.Item>
            </Col>
            <Col xs={24} md={20}>
              <Row gutter={[8, 8]}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="name"
                    label="Nome"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="cpf"
                    label="CPF ou CNPJ"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        validator: (_, value) => {
                          if (!value) {
                            return Promise.resolve();
                          }
                          value = value.replace(/\D/g,'');
                          // valida CPF
                          if (value.length === 11) {
                            if(!checkCPF(value)){
                              return Promise.reject(
                                "CPF inválido"
                              );
                            }
                            return Promise.resolve();
                          }
                          // valida CNPJ
                          if(value.length === 14) {
                            if(!checkCNPJ(value)){
                              return Promise.reject(
                                "CNPJ inválido"
                              );
                            }
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            "Valor inválido"
                          );
                        }
                      }
                    ]}
                  >
                    <InputMask
                      mask={cpfFieldLength > 11 || cpfFieldLength === 0 ? "99.999.999/9999-99" :  "999.999.999-999"}
                      maskChar=""
                      onChange={(e) => setCpf_user(e.target.value)}

                    >
                      {(inputProps) => (
                        <Input
                          {...inputProps}
                          placeholder="000.000.000-00"
                        />
                      )}
                    </InputMask>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={24} md={12}>
                  <Form.Item name="email" label="E-mail">
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="phone"
                    label="Telefone"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <MaskedInput
                      mask="(00) 00000-0000"
                      placeholder="(00) 00000-0000"
                      allowClear={true}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Divider orientation="left" orientationMargin="0">Protheus</Divider>
          <Row gutter={[8, 8]}>
            <Col xs={8}>
              <Form.Item
                name="code_user_protheus"
                label="Código do Usuário no Protheus"
              >
                <MaskedInput
                  mask="000000"
                  allowClear={true}
                // onChange={(e) => setCode_user_protheus(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col xs={16}>
              <Form.Item
                name="name_user_protheus"
                label="Nome do Usuário no Protheus"
              >
                <Input
                  readOnly
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col xs={8}>
              <Form.Item
                name="seller_code"
                label="Código do Vendedor no Protheus"
              >
                <MaskedInput
                  mask="000000"
                  allowClear={true}
                  onChange={(e) => setCode_seller_protheus(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col xs={16}>
              <Form.Item
                name="name_seller_protheus"
                label="Nome do Vendedor no Protheus"
              >
                <Input
                  readOnly
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider orientation="left" orientationMargin="0">Expedição</Divider>
          <Row gutter={[8, 8]}>
            <Col xs={24} md={24}>
              <Form.Item label="Identificação do Entregador" name="carrier_id">
                <Select
                  showSearch
                  optionFilterProp="children"
                >
                  {shippingCompanies.map((shippingCompany) => {
                    return (
                      <Option value={shippingCompany.codigo}>
                        {`(${shippingCompany.cnpj
                          .replace(/\D/g, "")
                          .replace(
                            /^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/,
                            "$1 $2 $3/$4-$5"
                          )}) ${shippingCompany.apelido}`}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Divider orientation="left" orientationMargin="0">App Morada da Paz</Divider>
          <Row gutter={[8, 8]}>
            <Col xs={8}>
              <Form.Item
                name="code_customer_protheus"
                label="Código do Cliente no Protheus"
              >
                <Input allowClear />
              </Form.Item>
            </Col>
          </Row>

          <Divider orientation="left" orientationMargin="0">Permissões</Divider>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Steps
                type="navigation"
                size="small"
                current={stepCurrent}
                onChange={(value) => setStepCurrent(value)}
                className="site-navigation-steps"
              >
                {steps.map((item) => (
                  <Steps.Step key={item.title} title={item.title} />
                ))}
              </Steps>
            </Col>
            <Col span={24}>{steps[stepCurrent].content}</Col>
          </Row>

          {/**
             <Row gutter={[8]}>
              <Col lg={24} xl={12}>
                <Form.Item name="id_profile" label="Perfis">
                  <TreeSelect
                    showSearch
                    value={user.profiles}
                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                    style={{ width: "100%" }}
                    placeholder="Selecione os perfis"
                    allowClear
                    multiple
                    treeDefaultExpandAll
                    onPopupScroll={onPopupScrollProfile}
                  >
                    {profiles.length !== 0
                      ? profiles.rows.map((profile) => {
                        return (
                          <TreeNode
                            key={profile.id}
                            title={profile.name}
                            value={profile.id}
                          />
                        );
                      })
                      : null}
                  </TreeSelect>
                </Form.Item>
              </Col>
              <Col lg={24} xl={12}>
                <Form.Item name="id_company" label="Empresas">
                  <TreeSelect
                    showSearch
                    value={user.companies}
                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                    style={{ width: "100%" }}
                    placeholder="Selecione as empresas"
                    allowClear
                    multiple
                    treeDefaultExpandAll
                    onPopupScroll={onPopupScrollCompany}
                  >
                    {companies.length !== 0
                      ? companies.rows.map((company) => (
                        <TreeNode
                          key={company.id}
                          title={company.name}
                          value={company.id}
                        >
                          {company.children.map((children) => (
                            <TreeNode
                              key={children.id}
                              title={children.name}
                              value={children.id}
                            />
                          ))}
                        </TreeNode>
                      ))
                      : null}
                  </TreeSelect>
                </Form.Item>
              </Col>
            </Row>
            


            */}

          <div className="buttonsClass">

            <Button
              disabled={uploadImageLoading}
              type="primary"
              onClick={async () => {
                await reset();
              }}
              style={{
                ...checkControl(signin.user.profiles, "save_and_update_user"),
                marginLeft: "8px",
                marginRight: "auto",
              }}
            >
              Redefinir senha
            </Button>

            {user.id && !user.active ? (
              <Form.Item name="active" valuePropName="checked">
                <Switch checkedChildren="Ativo" unCheckedChildren="Inativo" />
              </Form.Item>
            ) : null}

            <Form.Item>
              {user.active ? (
                <Popconfirm
                  title="Deseja inativar o usuário?"
                  onConfirm={remove}
                  okText="Sim"
                  cancelText="Não"
                >
                  <Button
                    type="danger"
                    style={checkControl(
                      signin.user.profiles,
                      "inactivate_user"
                    )}
                  >
                    Inativar
                  </Button>
                </Popconfirm>
              ) : null}

              <Button
                disabled={uploadImageLoading}
                type="primary"
                htmlType="submit"
                style={{
                  ...checkControl(signin.user.profiles, "save_and_update_user"),
                  marginLeft: "8px",
                }}
              >
                Salvar
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </UserContext.Provider>
  );
};

export default FormApp;
